@charset "utf-8";






/* --------------------------------
    Theme CSS for EC-CUBE 3
-------------------------------- */


/* --------------------------------
    Common
-------------------------------- */

body {
    font-family :"Times New Roman", "游明朝", YuMincho,"ヒラギノ明朝 Pr6 W6","Hiragino Mincho Pro", "HGS明朝E","メイリオ", "ＭＳ Ｐ明朝","MS PMincho",Meiryo, serif;
    line-height: 1.4;
    background-color: #fff;
    color: #525263;
    -webkit-text-size-adjust: 100%;
}



.img_center,
.txt_center {
    text-align: center;
}

.img_right,
.txt_right {
    text-align: right;
}







/****** Base Layout Setting ********/

#header {}

#contents {
    /*background: #fff;*/
}







/****** Header Setting ********/

.header_logo_area {
    /* margin-right: 100px; */
}

.header_logo_area .header_logo a:link,
.header_logo_area .header_logo a:visited,
.header_logo_area .header_logo a:hover,
.header_logo_area .header_logo a:active {
    text-decoration: none;
    color: #000;
}

.header_logo_area .header_logo a:hover {
    color: #666;
}







/****** Side Setting ********/


/****** Footer Setting ********/


/****** Effect Setting ********/


/****** Each BlockParts Setting ********/


/* cart area */

#cart_area {
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 11;
    font-size: 1rem;
}

#cart_area p {
    margin: 0;
}
#cart .inner {
    background: #f3f3f3;
}



/* search area */

#search {
    padding: 10px 3%;
}

#search select {
    /*width: 100%;*/
}







/* snsbtn_area */

.snsbtn_area .snsbtn {
    float: left;
    height: 20px;
    margin: 0 10px 10px 0;
}







/* --------------------------------
    Top Page
-------------------------------- */

.front_page a:hover img {
    opacity: 1;
}

.front_page #contents .row>div {}

.front_page #contents .row.no-padding>div {
    padding-bottom: 1;
}







/* Comment Area */

.front_page .comment_area h4 {
    font-size: 24px;
    font-size: 2.4rem;
    margin: 0 0 2px;
}

.front_page .comment_area h5 {
    font-size: 16px;
    font-size: 1.6rem;
    margin: 0;
}

.front_page .comment_area p {
    margin: 10px 0 15px;
}







/* Item List */

.front_page .item_comment {
    color: #444;
}

.front_page .item a {
    padding-bottom: 0;
}

.front_page .item a:hover {
    opacity: 1;
}

.front_page .item a:hover img {
    opacity: 1;
}

.front_page #contents_top .item_comment {
    color: #525263;
    font-weight: normal;
    font-size: 14px;
    font-size: 1.4rem;
}

.front_page #contents_top .item_comment.text-warning,
.front_page #contents_top .item_name.text-warning {
    color: #9a947e;
    font-weight: bold;
    font-size: 16px;
    font-size: 1.6rem;
}

.front_page .more_link.text-warning {
    font-weight: bold;
    color: #9A947E;
}







/* news_area */

.news_contents {
    padding: 0;
}

#news_area {
    clear: both;
}

#news_area .heading01 {
    padding: 8px;
    text-align: center;
    margin: 0;
}







/* txt_bnr */

.txt_bnr_area {
    padding: 0 0 16px;
}

.txt_bnr {
    background: #f8f8f8;
    border: 1px solid #D9D9D9;
    font-weight: bold;
    padding: 16px;
    margin: 12px 0 0;
    text-align: center;
}

.txt_bnr strong {
    color: #DE5D50;
    font-size: 20px;
    font-size: 2rem;
}

.txt_bnr strong strong {
    font-size: 32px;
    font-size: 3.2rem;
}

.item_gallery {
    clear: both;
}







/* --------------------------------
    Detail Page
-------------------------------- */

#item_detail+row {
    margin-top: -1px;
    background: #fff;
}







/*#item_detail .btn_area li:last-child {
    padding-left: 0;
}
*/


/* Slider */

.slick-loading .slick-list {
    background: #fff url('ajax-loader.gif') center center no-repeat;
}







/* Arrow */

.slick_prev,
.slick_next {
    font-size: 18px;
    font-size: 1.8rem;
    width: 40px;
    height: 40px;
    line-height: 40px;
}

#item_photo_area .slick-slide {
    text-align: center;
    padding: 0 10px;
}

#item_photo_area .slick-slide img {
    display: inline-block;
    border-radius: 10px;
}







/******************************
    Media Query
*******************************/

@media only screen and (max-width: 1020px) {
    /* --------- common ---------- */
    /****** Header Setting ********/
    /****** Side Setting ********/
    /****** Effect Setting ********/
}

@media only screen and (min-width: 1021x) {
    /* --------- common ---------- */
    #contents {}
    /****** Header Setting ********/
    /****** Layout ********/
    /* header */
    #header .header_logo_area {
        /* float: left; */
        /* margin-right: 265px; */
    }
    #header #drawer #search {
        position: relative;
    }
    #search {}
    #member {}
    .member ul li {}
    /****** Side Setting ********/
    /****** Footer Setting ********/
    /****** Each BlockParts Setting ********/
    /* search area */
    #search select {
        /*width: auto;
        max-width: 180px;*/
    }
    /* cart */
    #cart .inner {
        position: absolute;
        right: 0;
        top: 0;
    }
    #cart_area {
        top: 0;
        right: 16px;
    }
    #cart_area p {
        /*border: 1px solid #D9D9D9;
        float: right;
        min-width: 256px;*/
    }
    #cart_area p.cart-trigger {
        position: static;
        right: 0;
        top: 0;
        height: auto;
    }
    #cart_area .inner {
        /*min-width: 256px;*/
    }
    #cart_area .cart_price {
        font-size: 12px;
    }
    #cart_area .cart_price .price {
        font-size: 16px;
    }
    /* --------------------------------
        Top Page
    -------------------------------- */
    .front_page #contents .row {
        padding-bottom: 24px;
    }
    /* Comment Area */
    .front_page .comment_area h4 {
        font-size: 32px;
        font-size: 3.2rem;
        margin: 0 0 8px;
    }
    .front_page .comment_area h5 {
        font-size: 20px;
        font-size: 2rem;
    }
    .front_page .comment_area p {
        font-size: 20px;
        font-size: 2rem;
        margin: 24px 0 24px;
    }
    /* news_area */
    .news_contents {
        padding: 0 16px 0 0;
    }
    #news_area .heading01 {
        padding: 16px;
        text-align: left;
    }
    #news_area {}
    /* txt_bnr */
    .txt_bnr_area {
        padding-left: 16px;
    }
    .txt_bnr {
        margin: 0 0 16px;
    }
    /* --------------------------------
        Detail Page
    -------------------------------- */
    #item_photo_area .slick-dots {
        margin: 0 -16px;
        position: relative;
        bottom: 0;
    }
    #item_photo_area .slick-dots::after {
        content: "";
        display: table;
        clear: both;
    }
    #item_photo_area .slick-dots li {
        width: 33.33333333%;
        height: auto;
        padding: 16px;
        margin: 0;
        float: left;
    }
    .slick-dots li button.thumbnail {
        width: 100%;
        height: auto;
        padding: 0;
    }
    .slick-dots li button.thumbnail img {
        width: 100%;
        height: auto;
        opacity: 0.8;
    }
    .slick-dots li button.thumbnail:hover img,
    .slick-dots li.slick-active button.thumbnail img {
        opacity: 1;
    }
    .slick-dots li button.thumbnail::before {
        display: none;
    }
}







/* --------------------------------
    display mask
-------------------------------- */

.prevention-masked {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3040;
    background-color: #000;
    filter: alpha(opacity=50);
    opacity: .5;
}

.extra-form {
    margin: 10px 0;
    float: left;
}